import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import headerImage from "../images/logo_header.png";

const Topbar = () => {
  return (
    <AppBar position="static" style={{ background: '#dee2e9' }}>
      <Toolbar variant="dense">
        <Typography variant="subtitle2" sx={{ flexGrow: 1, fontWeight: "bold" }}>
          GUEST PORTAL
        </Typography>
          <img src={headerImage} alt="profile" height="70"/>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
