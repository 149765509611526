import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import profileImage from "../images/profile.jfif";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const images = [
  "//placekitten.com/1500/500",
  "//placekitten.com/4000/3000",
  "//placekitten.com/800/1200",
  "//placekitten.com/1500/1500",
];

const Hero = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const [isLightBoxOpen, setIsLightBoxOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const prevIndex = (photoIndex + images.length - 1) % images.length;
  const nextIndex = (photoIndex + 1) % images.length;
  const openLightBox = () => setIsLightBoxOpen(true);
  const closeLightBox = () => setIsLightBoxOpen(false);
  const gotoPrevImage = () => setPhotoIndex(prevIndex);
  const gotoNextImage = () => setPhotoIndex(nextIndex);

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <ImageList sx={{ m: 0 }} gap={1} cols={isBigScreen ? 3 : (isTabletScreen ? 2: 1)}>
          <ImageListItem key={0}>
            <img src={images[0]} alt={images[0]} maxHeight={200} loading="lazy" onClick={openLightBox}/>
          </ImageListItem>
          {isTabletScreen && (
            <>
              <ImageListItem key={1}>
                <img src={images[1]} alt={images[1]} loading="lazy" onClick={openLightBox}/>
              </ImageListItem>
            </>
          )}
          {isBigScreen && (
            <>
              <ImageListItem key={2}>
                <img src={images[2]} alt={images[2]} loading="lazy" onClick={openLightBox}/>
              </ImageListItem>
            </>
          )}
        </ImageList>

        <Paper sx={{ position: "absolute", bottom: 20, left: 20 }}>
          <img src={profileImage} alt="profile" height="100" />
        </Paper>
        <Box sx={{ position: "absolute", bottom: 20, right: 20 }}>
          <Rating value={3} readOnly />
          <br />
          <Button variant="contained" onClick={openLightBox}>
            Image Gallery
          </Button>
        </Box>
      </Box>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[nextIndex]}
          prevSrc={images[prevIndex]}
          onCloseRequest={closeLightBox}
          onMovePrevRequest={gotoNextImage}
          onMoveNextRequest={gotoPrevImage}
        />
      )}
    </>
  );
};

export default Hero;
