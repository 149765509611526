import React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

const EditForm = () => {
  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={4}>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            CHECK-IN
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth size="small" type="date" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth size="small" placeholder="Monday" disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth size="small" type="date" disabled />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            CHECK-OUT
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth size="small" type="date" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth size="small" placeholder="Monday" disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth size="small" type="date" disabled />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            DETAILS
          </Typography>
          <Divider sx={{ my: 1 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth size="small" label="Object" value="Apartment Olive" disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth size="small" label="OTA" value="" disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth size="small" label="Adults" type="number" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth size="small" label="Children" type="number" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth size="small" label="Pets" type="number" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel label="Parking space" control={<Checkbox size="small" />} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel label="Baby crib" control={<Checkbox size="small" />} />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            NOTES
          </Typography>
          <Divider sx={{ my: 1 }} />
          <TextField fullWidth disabled size="small" value="" />
        </Box>

        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            CONTACT INFORMATION
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth disabled size="small" label="Name" value="" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth disabled size="small" label="Email" value="" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth disabled size="small" label="Telephone" value="" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth disabled size="small" label="Country" value="" />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Paper>
  );
};

export default EditForm;
