import React from "react";
import Grid from "@mui/material/Grid";
import MessageForm from "../MessageForm";
import ContactInformation from "../ContactInformation";

const ReservationPage = () => {
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12} md={8}>
        <MessageForm />
      </Grid>
      <Grid item xs={12} md={4}>
        <ContactInformation />
      </Grid>
    </Grid>
  );
};

export default ReservationPage;
