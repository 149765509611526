import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import AddGuestPopup from "./AddGuestPopup";

const SelfCheck = () => {
  return (
    <Card>
      <CardHeader title={<Typography sx={{ fontWeight: "bold" }}>Self check in details</Typography>} />
      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>ID number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Link underline="none">1</Link>
                </TableCell>
                <TableCell>
                  <Link underline="none">Sisj</Link>
                </TableCell>
                <TableCell>
                  <Link underline="none">Jdkd</Link>
                </TableCell>
                <TableCell>
                  <Link underline="none">Sjkdmdkd</Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <AddGuestPopup />
      </CardContent>
      <CardActions>
        <Typography sx={{ ml: 1 }}>Adults: 1 Children: 0</Typography>
      </CardActions>
    </Card>
  );
};

export default SelfCheck;
