import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const MessageForm = () => {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="subtitle1">Message Petar Blažević</Typography>
      <TextField placeholder="Message text..." margin="normal" multiline fullWidth minRows={4} maxRows={15} />
      <Button variant="contained" color="primary" fullWidth>
        Send
      </Button>
    </Paper>
  );
};

export default MessageForm;
