import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Paper position="static" square>
      <Toolbar variant="dense">
        {isBigScreen ? (
          <Box>
            <Button component={Link} to="/">
              HOMEPAGE
            </Button>
            <Button component={Link} to="/reservation">
              RESERVATION
            </Button>
            <Button component={Link} to="/guest">
              GUESTS
            </Button>
            <Button component={Link} to="/payment">
              PAYMENTS
            </Button>
            <Button component={Link} to="/message">
              MESSAGES
            </Button>
            <Button component={Link} to="/review">
              REVIEW
            </Button>
          </Box>
        ) : (
          <Box>
            <Button color="inherit" onClick={handleClick}>
              Menu
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem component={Link} to="/">
                HOMEPAGE
              </MenuItem>
              <MenuItem component={Link} to="/reservation">
                RESERVATION
              </MenuItem>
              <MenuItem component={Link} to="/guest">
                GUESTS
              </MenuItem>
              <MenuItem component={Link} to="/payment">
                PAYMENTS
              </MenuItem>
              <MenuItem component={Link} to="/message">
                MESSAGES
              </MenuItem>
              <MenuItem component={Link} to="/review">
                REVIEW
              </MenuItem>
            </Menu>
          </Box>
        )}

        <IconButton size="large" edge="end" color="inherit" sx={{ ml: "auto" }}>
          G
        </IconButton>
      </Toolbar>
    </Paper>
  );
};

export default Navbar;
