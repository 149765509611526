import React from "react";
import Grid from "@mui/material/Grid";
import ContactInformation from "../ContactInformation";
import Transactions from "./Transactions";
import ReservationDetails from "../ReservationDetails";

const PaymentPage = () => {
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Transactions />
      </Grid>
      <Grid item xs={12}>
        <Transactions />
      </Grid>
      <Grid item xs={12} md={6}>
        <ReservationDetails />
      </Grid>
      <Grid item xs={12} md={6}>
        <ContactInformation />
      </Grid>
    </Grid>
  );
};

export default PaymentPage;
