import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const SelfCheck = () => {
  return (
    <Card>
      <CardHeader
        title={<Typography sx={{ fontWeight: "bold" }}>Self check in details (open door remotly)</Typography>}
      />
      <CardContent></CardContent>
    </Card>
  );
};

export default SelfCheck;
