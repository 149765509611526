import React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

const ReservationDetails = () => {
  return (
    <Card>
      <Stack divider={<Divider />}>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <Typography>
            Reservation details
            <Typography variant="caption" component="span" sx={{ ml: 1 }}>
              2043897
            </Typography>
          </Typography>
          <Typography>Reservation status:</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <Typography variant="subtitle2">Rent confimed</Typography>
          <Button variant="contained" color="success" size="small">
            <Typography variant="caption" component="span">
              EDIT RESERVATION DETAILS
            </Typography>
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Typography variant="body2">Name:</Typography>
                <Typography variant="body2">Email:</Typography>
                <Typography variant="body2">Tel:</Typography>
                <Typography variant="body2">Country:</Typography>
                <Typography variant="body2">External ID:</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Typography variant="body2">Arrival date: 2022-08-08</Typography>
                <Typography variant="body2">Check-in: 14:00 h</Typography>
                <Typography variant="body2">Departure date: 2022-08-22</Typography>
                <Typography variant="body2">Check out until: 10:00 h</Typography>
                <Typography variant="body2">Booking source:</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>Price of reservation (14 nights):</Typography>
          <Typography sx={{ fontWeight: "bold" }}>0.00 €</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>Total price:</Typography>
          <Typography sx={{ fontWeight: "bold" }}>0.00 €</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>Payment details:</Typography>
          <Typography variant="body2">
            for help
            <Link underline="hover"> please contact us</Link>
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Total: <Typography component="span">0.00 €</Typography>
          </Typography>
          <Typography variant="caption">not paid yet</Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Deposit: 100 EUR
          </Typography>
          <Typography variant="caption">
            in case it was charged on your arrival, damage deposit will be returned at the end of your stay
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default ReservationDetails;
