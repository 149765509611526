import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

const ContactInformation = () => {
  return (
    <Card>
      <CardHeader title={<Typography sx={{ fontWeight: "bold" }}>Contact information</Typography>} />
      <CardContent>
        <List dense>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText component="a" primary="Name: Petar Blzevic" />
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
          <ListItem disablePadding>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Email: info@holidayincroatia.net" />
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
          <ListItem disablePadding>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Phone: +385917957709" />
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
          <ListItem disablePadding>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Phone: +385917957709" />
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
          <ListItem disablePadding>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Web: https://HolidayInCroatia.net/social" />
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
          <ListItem disablePadding>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Map: Maršala Tita 26, Selce, Croatia" />
            </ListItemButton>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default ContactInformation;
