import React from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Info from "./Info";
import Welcome from "./Welcome";
import Directions from "./Directions";
import ReservationDetails from "../ReservationDetails";
import SelfCheckDoor from "../SelfCheckDoor";
import SelfCheck from "../SelfCheck";
import ContactInformation from "../ContactInformation";

const HomePage = () => {
  return (
    <>
      <Info />
      <Divider sx={{ mb: 2 }} />
      <Welcome />
      <Divider sx={{ mt: 2 }} />

      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} md={6}>
          <ReservationDetails />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <SelfCheckDoor />
            <SelfCheck />
            <ContactInformation />
            <Directions />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default HomePage;
