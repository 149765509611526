import React from "react";
import Grid from "@mui/material/Grid";
import EditForm from "./EditForm";
import MessageForm from "../MessageForm";
import SelfCheck from "../SelfCheck";
import Stack from "@mui/material/Stack";

const ReservationPage = () => {
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12} md={8}>
        <EditForm />
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={2}>
          <MessageForm />
          <SelfCheck />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ReservationPage;
