import React from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Chip from "@mui/material/Chip";

const Info = () => {
  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={5} md={3}>
          <List>
            <ListItem disablePadding>
              <ListItemText primary="Person max:" secondary={null} />
              <ListItemSecondaryAction>
                <Typography variant="body2">4</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText secondary={null} primary="Beds:" />
              <ListItemSecondaryAction>
                <Typography variant="body2">1</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText secondary={null} primary="Bedrooms:" />
              <ListItemSecondaryAction>
                <Typography variant="body2">1</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText secondary={null} primary="Bathrooms:" />
              <ListItemSecondaryAction>
                <Typography variant="body2">1</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText secondary={null} primary="Toilets:" />
              <ListItemSecondaryAction>
                <Typography variant="body2">1</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText secondary={null} primary="Size:" />
              <ListItemSecondaryAction>
                <Typography variant="body2">55 m2</Typography>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
        <Grid item xs>
          <Typography variant="h6">Apartment Olive</Typography>
          <Typography variant="body2">
            South side apartment with the garden. The apartment has a bedroom with a queen size bed and a pull out couch
            in living room. It has a balcony overlooking the sea and it is 200 meters from the beach!
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
        <Grid item xs={12} md={3}>
          <Chip color="primary" size="small" sx={{ m: 0.3 }} label="PARKING" />
          <Chip color="primary" size="small" sx={{ m: 0.3 }} label="TERRACE" />
          <Chip color="primary" size="small" sx={{ m: 0.3 }} label="WI-FI" />
          <Chip color="primary" size="small" sx={{ m: 0.3 }} label="FAST INTERNET" />
          <Chip color="primary" size="small" sx={{ m: 0.3 }} label="INTERNET" />
          <Chip color="primary" size="small" sx={{ m: 0.3 }} label="AIR CONDITIONING" />
          <Chip color="primary" size="small" sx={{ m: 0.3 }} label="LCD TV" />
          <Chip color="primary" size="small" sx={{ m: 0.3 }} label="NEAR BEACH" />
        </Grid>
      </Grid>
    </>
  );
};

export default Info;
