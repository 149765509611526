import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const documentTypes = [
  "Personal ID",
  "Personal passport (Croatia)",
  "Personal ID (Croatia)",
  "Family passport",
  "Mutual passport",
  "Children's passport",
  "Diplomatic passport",
  "Official passport",
  "Maritime booklet",
  "Shipping booklet",
  "Foreigners travel certificate - issued by the Republic of Croatia",
  "Refugees travel documents",
  "Stateless persons travel documents",
  "Forfeitured or retained travel documents certificate",
  "Lost or missing travel documents certificate",
  "Foreigners personal ID - issued by the republic of Croatia",
  "Special personal ID - Diplomatic, consular, official",
  "International agreements based documents",
  "Frontier pass",
  "A refugee card - issued by the Republic of Croatia",
  "Drivers license",
  "Children's passport (Croatia)",
  "Foreigners travel certificate (foreign)",
  "Mutual passport (Croatia)",
  "Diplomatic passport (Croatia)",
  "Official passport (Croatia)",
  "Maritime booklet (Croatia)",
  "Other",
  "Family passport (Croatia)",
  "Drivers license (Croatia)",
  "Health card",
];
const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei ",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Côte d'Ivoire",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia (Czech Republic)",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  'Eswatini (fmr. "Swaziland")',
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (formerly Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const AddGuestPopup = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth variant="contained" onClick={handleClickOpen} sx={{ mt: 2 }}>
        + Add guest
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <AppBar elevation={0} sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6">
              Add New Guest
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: 3 }}>
          <Stack spacing={4}>
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                GENERAL
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth size="small" label="First Name" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth size="small" label="Last Name" />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="document-type-select-label">Document Type</InputLabel>
                    <Select
                      labelId="document-type-select-label"
                      label="Document Type"
                      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    >
                      {documentTypes.map((d, i) => (
                        <MenuItem key={i} value={d}>
                          {d}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth size="small" label="Document Number" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select labelId="gender-select-label" label="Gender">
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                RESIDENCE
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField fullWidth size="small" label="Residence Country" />
                <TextField fullWidth size="small" label="Residence City" />
              </Stack>
            </Box>
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                BIRTH DATA
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField fullWidth size="small" label="Date of Birth" />
                <FormControl fullWidth size="small">
                  <InputLabel id="citizenship-select-label">Citizenship</InputLabel>
                  <Select
                    labelId="citizenship-select-label"
                    label="Citizenship"
                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                  >
                    {countries.map((d, i) => (
                      <MenuItem key={i} value={d}>
                        {d}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained">Add Guest</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddGuestPopup;
