import React from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const Welcome = () => {
  return (
    <Container maxWidth="md">
      <Paper variant="outlined" sx={{ textAlign: "center", p: 4 }}>
        <Typography variant="h5" sx={{ m: 2 }}>
          Welcome !
        </Typography>
        <Typography variant="body2">
          Thank you for your reservation and welcome to our guest portal.Here you can find information about your
          reservation and accommodation unit.Please, fill the form with guest details who will be staying in this
          property.This will speed up your process of registration once you arrive.We wish you a pleasant stay!
        </Typography>
      </Paper>
    </Container>
  );
};

export default Welcome;
