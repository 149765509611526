import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import L from "leaflet";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const Directions = () => {
  const defaultPosition = [45.1603795, 14.7180952]; // Paris position
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <Card>
      <CardHeader title={<Typography sx={{ fontWeight: "bold" }}>Directions</Typography>} />
      <CardContent>
        <Button variant="outlined">Destination</Button>
        <FormControl fullWidth margin="normal" size="small">
          <Select value="Driving">
            <MenuItem value="Driving">Driving</MenuItem>
            <MenuItem value="Cycling">Cycling</MenuItem>
            <MenuItem value="Transit">Transit</MenuItem>
            <MenuItem value="Walking">Walking</MenuItem>
          </Select>
        </FormControl>
        <Stack direction="row" alignItems="center" spacing={1}>
          <TextField size="small" sx={{ flexGrow: 1 }} placeholder="your starting address" />
          <Button size="large" variant="outlined">
            Plan route
          </Button>
        </Stack>
      </CardContent>
      <Paper elevation={0} sx={{ m: 2, height: "300px" }}>
        <MapContainer center={defaultPosition} zoom={13}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={defaultPosition} />
        </MapContainer>
      </Paper>
    </Card>
  );
};

export default Directions;
