import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import ColorGray from "@mui/material/colors/grey"
import Container from "@mui/material/Container";
import Topbar from "./components/Topbar";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import HomePage from "./components/Home/HomePage";
import ReservationPage from "./components/Reservation/ReservationPage";
import MessagePage from "./components/Message/MessagePage";
import GuestPage from "./components/Guest/GuestPage";
import PaymentPage from "./components/Payment/PaymentPage";
import ReviewPage from "./components/Review/ReviewPage";

function App() {
  return (
    <Router>
      <Container component="main" disableGutters sx={{ backgroundColor: ColorGray['A100'] }}>
        <Topbar />
        <Hero />
        <Navbar />

        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/reservation">
            <ReservationPage />
          </Route>
          <Route exact path="/guest">
            <GuestPage />
          </Route>
          <Route exact path="/payment">
            <PaymentPage />
          </Route>
          <Route exact path="/message">
            <MessagePage />
          </Route>
          <Route exact path="/review">
            <ReviewPage />
          </Route>
        </Switch>
      </Container >
    </Router>
  );
}

export default App;
