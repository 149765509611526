import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { Button, TextField } from "@mui/material";

const ReviewForm = () => {
  return (
    <Grid container spacing={4} padding={2}>
      <Grid item xs={12} md={4}>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="caption" component="legend" fontWeight="bold" sx={{ pr: 2 }}>
            LISTING ACCURACY
          </Typography>
          <Rating />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="caption" component="legend" fontWeight="bold" sx={{ pr: 2 }}>
            CHECK-IN EXPERIENCE
          </Typography>
          <Rating />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="caption" component="legend" fontWeight="bold" sx={{ pr: 2 }}>
            ACCOMMODATION CLEANLINESS
          </Typography>
          <Rating />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="caption" component="legend" fontWeight="bold" sx={{ pr: 2 }}>
            OWNER COMMUNICATION
          </Typography>
          <Rating />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="caption" component="legend" fontWeight="bold" sx={{ pr: 2 }}>
            LOCATION
          </Typography>
          <Rating />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="caption" component="legend" fontWeight="bold" sx={{ pr: 2 }}>
            PRICE
          </Typography>
          <Rating />
        </Stack>
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField fullWidth margin="dense" size="small" placeholder="Review Subject" />
        <TextField
          fullWidth
          margin="dense"
          size="small"
          placeholder="Please write a review..."
          multiline
          minRows={4}
          maxRows={12}
        />
        <Button fullWidth variant="contained" color="primary" sx={{ mt: 1 }}>
          Submit
        </Button>
      </Grid>
      <Grid item xs={12} md={3} alignSelf="center">
        <Typography variant="caption" component="legend" textAlign="center" fontWeight="bold">
          REVIEW SCORE
        </Typography>
        <Typography variant="h1" textAlign="center">
          0.00
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReviewForm;
